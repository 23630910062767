/* Global variables */

:root {
  --primary-color: #212f54;
  --link-color: #0d4aab;
  --link-color-transparent: rgba(13, 74, 171, .7);
  --primary-tint-color: #53c2fc;
  --primary-tint-color-transparent: rgba(83, 194, 252, .75);
  --secondary-color: #ba101b;
  --secondary-shade: #860b14;
  --on-primary: #fff;
  --on-secondary: #fff;
  --on-background: #354954;
  --background-color: #f5f6f5;
  --light-background-color: #edf0f2;
  --surface-color: #fff;
  --border-color: #b1b1b1;
  --light-border-color: #dfdfdf;
  --skeleton-color: #e5e6e9;
  --separator-color: #ddd;
  --green: #11912e;
  --red: #dc0000;
  --warn-color: #fc0;
  --disabled-color: var(--border-color);
  --not-white: #f3f3f3;
  --variable-color: #e01e51;

  --font-family: Lato, sans-serif;
  --border-radius: 4px;
  --round-radius: 100px;
  --drop-shadow: 0 2px 8px rgba(0, 0, 0, .5);
  --card-shadow: 0 2px 8px rgba(0, 0, 0, .2);
  --input-box-shadow: 0 0 20px 2px rgba(13, 74, 171, .1);
  --skeleton-animation: load-skeleton .75s infinite alternate;
  --arrow-box-width: 155px;
  --large-arrow-box-width: 250px;
  --icon-size: 40px;
  --user-menu-width: 300px;
}

/* Mobile variables */
@media only screen and (max-width: 750px) {
  :root {
      --sm-text-size: 11px;
      --m-text-size: 13px;
      --l-text-size: 18px;
      --xl-text-size: 24px;

      --spacing-size-a: 5px;
      --spacing-size-b: 10px;
      --spacing-size-c: 15px;
      --spacing-size-d: 20px;
      --spacing-size-e: 30px;

      --main-padding: var(--spacing-size-b);
      --max-logo-width: 260px;
      --max-login-width: 340px;
      --header-height: 50px;
      --card-max-width: calc(100% - 2 * var(--main-padding));
      --max-content-width: var(--card-max-width);
  }

  .only-on-desktop {
      display: none;
  }

  .footer-icon {
      display: none;
  }

  footer {
      display: block;
      text-align: center;
  }

  footer div:first-child {
      width: 100%;
  }

  .only-on-desktop {
      display: none !important;
  }
}
@media only screen and (max-width: 500px) {
  :root {
      --max-logo-width: 200px;
  }

  .hide-on-mobile {
      display: none !important;
  }

  .only-on-desktop {
      display: none !important;
  }
}


/* Tablet variables */
@media only screen and (min-width: 750px) {
  :root {
      --sm-text-size: 13px;
      --m-text-size: 15px;
      --l-text-size: 22px;
      --xl-text-size: 28px;

      --spacing-size-a: 7px;
      --spacing-size-b: 12px;
      --spacing-size-c: 17px;
      --spacing-size-d: 24px;
      --spacing-size-e: 40px;

      --main-padding: var(--spacing-size-c);
      --max-logo-width: 320px;
      --max-login-width: 400px;
      --header-height: 50px;
      --card-max-width: 600px;
      --max-content-width: var(--card-max-width);
  }

  .only-on-desktop {
      display: none !important;
  }
}


/* Desktop variables */
@media only screen and (min-width: 1024px) {
  :root {
      --sm-text-size: 13px;
      --m-text-size: 15px;
      --l-text-size: 22px;
      --xl-text-size: 28px;

      --spacing-size-a: 7px;
      --spacing-size-b: 12px;
      --spacing-size-c: 17px;
      --spacing-size-d: 24px;
      --spacing-size-e: 40px;

      --main-padding: var(--spacing-size-d);
      --max-logo-width: 320px;
      --max-login-width: 400px;
      --header-height: 59px;
      --card-max-width: 600px;
      --max-content-width: calc(2 * var(--spacing-size-d) + 2 * var(--card-max-width));
  }

  .only-on-desktop {
      display: block !important;
  }

  .hide-on-desktop {
      display: none !important;
  }
}
