html, body, #app {
  background-color: var(--background-color);
  color: var(--on-background);
  font-family: var(--font-family);
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

html, body, #app {
  font-size: var(--m-text-size);
}

#app > div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  flex: 1;
  padding: var(--spacing-size-d) var(--main-padding);
}

nav a {
  color: rgba(255,255,255,.85);
  font-size: var(--l-text-size);
}

nav a.active {
  background-color: var(--primary-tint-color-transparent);
  color: var(--on-primary);
}

nav a:hover {
  background-color: var(--primary-tint-color);
  color: var(--on-primary);
  text-decoration: none;
}

a, p {
  margin-bottom: var(--spacing-size-b);
}
a:empty, button:empty {
  cursor: default;
}

a:last-child, p:last-child {
  margin-bottom: 0;
}

a, .link-button {
  color: var(--link-color);
  text-decoration: none;
}
a:hover, .link-button {
  text-decoration: underline;
}

h1 {
  font-size: var(--xl-text-size);
  font-weight: normal;
  margin-bottom: var(--spacing-size-d);
  text-transform: capitalize;
}
h2 {
  font-size: var(--l-text-size);
  font-weight: normal;
  margin-bottom: var(--spacing-size-b);
}
h4 {
  font-size: var(--sm-text-size);
  margin-bottom: var(--spacing-size-b);
}

label, input {
  display: block;
}

label {
  font-size: var(--sm-text-size);
  margin-bottom: var(--spacing-size-a);
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
input[type='date'],
input[type='datetime-local'],
textarea {
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  font-size: var(--m-text-size);
  margin-bottom: var(--spacing-size-b);
  outline: 0;
  padding: var(--spacing-size-b);
  width: 100%;
}

input[type='text']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='date']:focus,
input[type='datetime-local']:focus,
select:focus,
textarea:focus {
  border-color: var(--link-color);
  box-shadow: var(--input-box-shadow);
}

input[type='text'].editable {
  background: transparent;
  border: 1px solid transparent;
  padding: 2px 4px;
  margin: 0;
}
input[type='text'].editable:hover {
  border: 1px solid var(--light-border-color);
  padding: 2px 4px;
}
input[type='text'].editable:focus {
  border-color: var(--link-color);
  box-shadow: var(--input-box-shadow);
}

input[disabled] {
  background-color: var(--light-border-color);
  cursor: not-allowed;
}

select {
  border-radius: var(--border-radius);
  box-sizing: border-box;
  height: var(--spacing-size-e);
  font-size: var(--m-text-size);
  margin-bottom: var(--spacing-size-b);
  outline: 0;
  width: 100%;
}

fieldset {
  display: flex;
  flex-direction: column;
  width: 100%;
}

var {
  background: var(--not-white);
  border: 1px solid var(--light-border-color);
  border-radius: var(--border-radius);
  color: var(--variable-color);
  font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;
  font-size: .9em;
  font-style: normal;
  padding: 0 2px;
  line-height: 1.6em;
  white-space: nowrap;
}

details section {
  background-color: var(--skeleton-color);
  padding: var(--spacing-size-b);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

summary {
  color: var(--link-color);
  cursor: pointer;
  padding: var(--spacing-size-b) 0;
}

details.table summary {
  color: var(--on-background);
  border: 1px solid var(--border-color);
  padding: var(--spacing-size-b);
}

details p {
  margin-bottom: var(--spacing-size-a);
}

.details-group {
  margin-bottom: var(--spacing-size-b);
}

.details-group > details:first-child summary {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  border-top: 1px solid var(--border-color);
  margin-top: 0;
}

.details-group > details summary {
  margin-top: -1px;
}

.details-group > details:last-child summary {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.details-group > details:last-child[open] summary {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.details-group > details:last-child[open] section {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  border-bottom: 1px solid var(--border-color);
}

[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}


/* Common classes */
.content-area {
  background: var(--surface-color);
  border-radius: var(--border-radius);
  display: grid;
  grid-gap: calc(2 * var(--main-padding));
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: var(--max-content-width);
  position: relative;
}

.bold {
  font-weight: bold;
}

.capital {
  text-transform: capitalize;
}

.left-checkbox-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: var(--spacing-size-b);
}
.left-checkbox-wrapper.radio {
  margin-bottom: 3px;
}
.left-checkbox-wrapper input[type='checkbox'] {
  margin: 0 var(--spacing-size-a) 0 0;
}
.left-checkbox-wrapper label {
  margin-bottom: 0;
}
.left-checkbox-wrapper label::after {
  /* Overrides the default '::after' pseudo-class content since the label doesn't need a colon if it's on the right */
  content: '' !important;
}

textarea.code {
  font-family: 'Courier New', monospace;
}

.two-column {
  display: grid;
  grid-gap: var(--spacing-size-b);
  grid-template-columns: 1fr 1fr;
}

.button, .swal-button {
  background-color: var(--secondary-color);
  border: 0;
  border-radius: var(--border-radius);
  color: var(--on-secondary);
  display: inline-block;
  font-size: var(--m-text-size);
  padding: var(--spacing-size-b);
  text-align: center;
  text-decoration: none;
}
.button:active, .link-button:active, .swal-button:active {
  outline: 0;
}
.button:hover, .swal-button:hover {
  background-color: var(--secondary-shade);
  text-decoration: none;
  cursor: pointer;
}
.link-button, .swal-button--cancel {
  background: transparent;
  border: 0;
  cursor: pointer;
  color: var(--link-color);
  display: inline-block;
  font-size: var(--m-text-size);
  font-weight: bold;
  margin-top: var(--spacing-size-c);
  margin-bottom: 0;
  padding: var(--spacing-size-b);
  text-align: center;
  text-decoration: none;
}
.link-button:hover, .swal-button--cancel:hover {
  text-decoration: underline;
}

.button.disabled, .button.disabled:hover {
  background-color: var(--border-color);
  color: var(--on-background);
  cursor: default;
}

.round {
  border-radius: var(--round-radius);
}

.swal-button:not([disabled]):hover {
  background-color: var(--secondary-shade);
}
.swal-button--cancel:not([disabled]):hover {
  background-color: transparent;
}

.word-break {
  word-break: break-all;
}

form .button {
  font-size: var(--m-text-size);
  font-weight: bold;
  position: relative;
}

.dark-background form .button {
  width: 100%;
}

form .button.loading:before {
  content: '';
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -11px;
  width: 20px;
  height: 20px;
  border: 4px solid;
  border-left-color: transparent;
  border-radius: 50%;
  opacity: 0;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.card {
  background-color: var(--surface-color);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  padding: var(--spacing-size-d);
  margin: var(--spacing-size-d) auto;
  max-width: var(--card-max-width);
}

p.text {
  line-height: 1.4em;
}

.search {
  background-position: right var(--spacing-size-a) center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
}

.split {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.split p:first-child {
  margin-bottom: 0;
}

.split p:last-child {
  min-width: 15%;
}

.number {
  background-color: var(--primary-color);
  border-radius: var(--round-radius);
  font-size: var(--l-text-size);
  padding: var(--spacing-size-a);
}

.dark-background {
  background: var(--primary-color);
}

.full, .full-message {
  min-height: 100%;
  min-width: 100%;
}

.full-message {
  align-items: center;
  display: flex;
  justify-content: center;
}

.block {
  display: block;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none;
}

.small-text {
  font-size: var(--sm-text-size);
}

.dark a {
  color: var(--primary-tint-color);
}
.dark {
  color: var(--on-primary);
}
.error {
  color: var(--red);
  font-weight: bold;
  margin-bottom: var(--spacing-size-d);
}
.success {
  color: var(--green);
  font-weight: bold;
  margin-bottom: var(--spacing-size-d);
}
.no-bottom-margin {
  margin-bottom: 0;
}

.positive-value {
  color: var(--green);
}

.negative-value {
  color: var(--red);
}

.right {
  float: right;
}

.right::after {
  content: ' ';
  clear: both;
}

.arrow-box {
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow);
  display: none;
  padding: var(--spacing-size-b);
  pointer-events: none;
  text-align: left;
  transform: translateX(-50%) translateY(-100%);
  min-width: var(--arrow-box-width);
}

.arrow-box p {
  margin-bottom: var(--spacing-size-a);
}

.arrow-box p:last-child {
  margin-bottom: 0;
}

.arrow-box:after, .arrow-box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box:after {
  border-top-color: var(--surface-color);
  border-width: 6px;
  margin-left: -6px;
}
.arrow-box:before {
  border-top-color: var(--border-color);
  border-width: 7px;
  margin-left: -7px;
}

.full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.center {
  text-align: center;
}
.block-center {
  margin-left: auto;
  margin-right: auto;
}

.no-pointer {
  cursor: default;
}

.text-details {
  width: 100%;
}

.text-details summary {
  padding: var(--spacing-size-a) 0 var(--spacing-size-a) 0;
}

.text-details[open] summary {
  margin-bottom: var(--spacing-size-a);
}

.text-details h3 {
  margin-top: var(--spacing-size-a);
}

.text-details p {
  line-height: 1.4em;
  margin-top: var(--spacing-size-a);
  margin-bottom: var(--spacing-size-c);
}

.text-details p:last-of-type {
  margin-bottom: var(--spacing-size-b);
}


.loading, .inline-loading {
  --size: 100px;
  display: block;
  position: relative;
  left: 50%;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #b1b1b1;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.inline-loading {
  margin: 0 0 0 calc(-.75 * var(--size));
}
.loading {
  top: 50%;
  margin: calc(-.75 * var(--size)) 0 0 calc(-.75 * var(--size));
}
.loading:before, .inline-loading:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #0d4aab;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.loading:after, .inline-loading:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ba101b;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
@keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes load-skeleton {
  from { opacity: 0; }
}

@media only screen and (max-width: 850px) {
  .card.multi-view {
      margin: 0 0 var(--spacing-size-d) 0;
  }

  .card.multi-view:first-of-type {
      margin-top: var(--spacing-size-d);
  }
}

@media only screen and (min-width: 851px) {
  .card.multi-view {
      min-width: 400px;
  }
}

/* --------------------------------------------- Date picker styles --------------------------------------------- */
.react-datepicker {
  border: 1px solid var(--border-color);
}

.react-datepicker__day--today {
  text-decoration: underline;
}

.react-datepicker__day--selected {
  background-color: var(--secondary-color);
}

.react-datepicker__day--selected:hover {
  background-color: var(--secondary-shade);
}

.react-datepicker__navigation--next {
  border-left-color: var(--primary-tint-color) !important;
}
.react-datepicker__navigation--next:hover {
  border-left-color: var(--on-secondary) !important;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--primary-tint-color) !important;
}
.react-datepicker__navigation--previous:hover {
  border-right-color: var(--on-secondary) !important;
}

.react-datepicker__navigation--previous:focus, .react-datepicker__navigation--next:focus {
  outline: 0;
}

.react-datepicker__input-time-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
}

.react-datepicker-time__caption {
  font-size: var(--m-text-size);
}

.react-datepicker-time__input input[type='time'] {
  border: 1px solid var(--border-color);
  font-size: var(--m-text-size);
  width: 100px !important;
}

.react-datepicker__header {
  background-color: var(--primary-color);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  border: 0;
}

.react-datepicker__day-name, .react-datepicker__current-month {
  color: #fff;
}

.react-datepicker-popper {
  z-index: 20;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-bottom-color: var(--primary-color) !important;
}

.react-datepicker__triangle {
  left: auto;
  right: 20px;
}
